import Modaal from "modaal";
import Swiper from "swiper";
import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class Top {
  constructor() {
    this.init();
  }
  init() {
    $(function() {
      $("#top").on('contextmenu', function() {
        return false;
      });
    });
    
    //APP DOWNLOAD
    $(".js-app-modal").modaal({
      overlay_opacity: '0.9',
      background: '#FFF',
    });
    $('.js-modal-close').on('click', function () {
      $('.js-app-modal').modaal('close');
    })
    
    //コミュニティガイドラインの詳細
    $(".js-top-slider-modal01").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal02").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal03").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal04").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal05").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal06").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal07").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal08").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal09").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal10").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal11").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal12").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal13").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal14").modaal({
      overlay_opacity: '0.3',
    });
    $('.js-modal-close').on('click', function () {
      $('.p-top__communitySlideContent').modaal('close');
    })
    
    //menuClose
    const menuClose = new TimelineMax();
    menuClose.to('.l-header__menu',0.3, {
      onComplete: function () {
        $('.l-header__menuBtn span').removeClass('active');
        $('.l-header__menuList').slideUp(300);
      }
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__leadContent--02',
      triggerHook: 'onLeave',
      offset: '-100',
//      return: true,
    })
    .setTween(menuClose)
//    .addIndicators({ name: "menuClose" })
    .addTo(controller);
    
    //leadContent01
    const leadContent01 = new TimelineMax();
    leadContent01.from('.p-top__leadContent--01',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__lead',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(leadContent01)
//    .addIndicators({ name: "leadContent01" })
    .addTo(controller);
    
    //leadContent02
    const leadContent02 = new TimelineMax();
    leadContent02.from('.p-top__leadContent--02',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__leadContent--02',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(leadContent02)
//    .addIndicators({ name: "leadContent02" })
    .addTo(controller);
    
    //howtoTtl
    const howtoTtl = new TimelineMax();
    howtoTtl.from('.p-top__howtoTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__howto',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(howtoTtl)
//    .addIndicators({ name: "howtoTtl" })
    .addTo(controller);
    
    //howtoSlider
    const howtoSlider = new TimelineMax();
    howtoSlider.from('.p-top__howtoSlider',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__howtoSlider',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(howtoSlider)
//    .addIndicators({ name: "howtoSlider" })
    .addTo(controller);
    
    //guideTtl
    const guideTtl = new TimelineMax();
    guideTtl.from('.p-top__guideContentTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__guide',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(guideTtl)
//    .addIndicators({ name: "guideTtl" })
    .addTo(controller);
    
    //guideContent01
    const guideContent01 = new TimelineMax();
    guideContent01.from('.p-top__guideContent--01',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__guideContent--01',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(guideContent01)
//    .addIndicators({ name: "guideContent01" })
    .addTo(controller);
    
    //guideContent02
    const guideContent02 = new TimelineMax();
    guideContent02.from('.p-top__guideContent--02',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__guideContent--02',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(guideContent02)
//    .addIndicators({ name: "guideContent02" })
    .addTo(controller);
    
    //communityTtl
    const communityTtl = new TimelineMax();
    communityTtl.from('.p-top__communityTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__community',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(communityTtl)
//    .addIndicators({ name: "communityTtl" })
    .addTo(controller);
    
    //communitySlide
    const communitySlide = new TimelineMax();
    communitySlide.from('.p-top__communitySlide',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__communitySlide',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(communitySlide)
//    .addIndicators({ name: "communitySlide" })
    .addTo(controller);
    
    //faqTtl
    const faqTtl = new TimelineMax();
    faqTtl.from('.p-top__faqTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__faq',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(faqTtl)
//    .addIndicators({ name: "faqTtl" })
    .addTo(controller);
    
    //faqContent01
    const faqContent01 = new TimelineMax();
    faqContent01.from('.p-top__faqContent--01',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__faqContent--01',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(faqContent01)
//    .addIndicators({ name: "faqContent01" })
    .addTo(controller);
    
    //faqContent02
    const faqContent02 = new TimelineMax();
    faqContent02.from('.p-top__faqContent--02',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__faqContent--02',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(faqContent02)
//    .addIndicators({ name: "faqContent02" })
    .addTo(controller);
    
    //faqContent03
    const faqContent03 = new TimelineMax();
    faqContent03.from('.p-top__faqContent--03',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__faqContent--03',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(faqContent03)
//    .addIndicators({ name: "faqContent03" })
    .addTo(controller);
    
    
    
    //スライダー
    new Swiper('.swiper-container--howto', {
      freeMode: true,
      slidesPerView: 'auto',
      spaceBetween: 20,
    })
    
    new Swiper('.swiper-container--community', {
      freeMode: true,
      initialSlide: '2',
      slidesPerView: 'auto',
      spaceBetween: 20,
    })
  }

}

export default Top;
