import Modaal from "modaal";
import Swiper from "swiper";
import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class basic {
  constructor() {
    this.init();
  }
  init() {
    
    $(function() {
      $("#basic").on('contextmenu', function() {
        return false;
      });
    });
    
    //menuClose
    const menuClose = new TimelineMax();
    menuClose.to('.l-header__menu',0.3, {
      onComplete: function () {
        $('.l-header__menuBtn span').removeClass('active');
        $('.l-header__menuList').slideUp(300);
      }
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionSubttl--02',
      triggerHook: 'onLeave',
//      offset: '-100',
//      return: true,
    })
    .setTween(menuClose)
//    .addIndicators({ name: "menuClose" })
    .addTo(controller);
    
    //ポップアップ
    $('.js-popupTrigger-01').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-01').toggleClass('active');
    })
    $('.js-popupTrigger-02').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-02').toggleClass('active');
    })
    $('.js-popupTrigger-03').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-03').toggleClass('active');
    })
    $('.js-popupTrigger-04').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-04').toggleClass('active');
    })
    $('.js-popupTrigger-05').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-05').toggleClass('active');
    })
    $('.js-popupTrigger-06').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-06').toggleClass('active');
    })
    $('.js-popupTrigger-07').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-07').toggleClass('active');
    })
    $('.js-popupTrigger-08').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-08').toggleClass('active');
    })
    $('.js-popupTrigger-09').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-09').toggleClass('active');
    })
    $('.js-popupTrigger-10').on('click', function () {
      if($('.p-basic__functionPopupListItem').hasClass('active')) {
        $('.p-basic__functionPopupListItem').removeClass('active');
      }
      $('.js-popupObject-10').toggleClass('active');
    })
    
    const video01 = new TimelineMax();
    video01.to('.p-basic__functionMovie',0.5, {
      onComplete: function () {
        const videoElement = $('.kasou-video').get(0);
        videoElement.play();
      }
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionMovie',
      triggerHook: 'onCenter',
      offset: '0',
      reverse: false,
    })
    .setTween(video01)
//    .addIndicators({ name: "video01" })
    .addTo(controller);
    
    
    //マッチした人のことをもっと知りたい？
    new Swiper('.swiper-container-basic', {
      loop: true,
      speed: 500,
      lidesPerView: 'auto',
      spaceBetween: 20,
      pagination: {
        el: '.swiper-pagination-basic',
        type: 'bullets',
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next-basic",
        prevEl: ".swiper-button-prev-basic",
      },
      on: {
        slideChangeTransitionEnd: function () {
          if($('.swiper-slide--01').hasClass('swiper-slide-active')){
            $('.p-basic__functionSliderTxt--01').fadeIn();
          } else {
            $('.p-basic__functionSliderTxt--01').hide();
          }
          if($('.swiper-slide--01').hasClass('swiper-slide-active')){
            $('.p-basic__functionSpeechTxt').css('opacity','1');
          } else {
            $('.p-basic__functionSpeechTxt').css('opacity','0');
          }
          if($('.swiper-slide--02').hasClass('swiper-slide-active')){
            $('.p-basic__functionSliderTxt--02').fadeIn();
          } else {
            $('.p-basic__functionSliderTxt--02').hide();
          }
        },
      }
    })
    
    
    //もっとTinderの機能を知ろう
    new Swiper('.swiper-container--functionMore', {
      freeMode: true,
      slidesPerView: 'auto',
      spaceBetween: 20,
    })
    
    
    //01 プロフィールの作り方 モーダル 閉じるボタン
    $('.js-functionModal-close').on('click', function () {
      $('.js-function-modal01').modaal('close');
    })
    //01 プロフィールの作り方 モーダル スライダー
    $(".js-function-modal01").modaal({
      overlay_opacity: '0.3',
      after_open: function(modal_wrapper) {
        new Swiper('.modal01-slider01', {
          loop: true,
          speed: 500,
          lidesPerView: 'auto',
          spaceBetween: 20,
          pagination: {
            el: '.modal01-slider01-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: ".modal01-slider01-next",
            prevEl: ".modal01-slider01-prev",
          },
          on: {
            slideChangeTransitionEnd: function () {
              if($('.modal01-slider01--01').hasClass('swiper-slide-active')){
                $('.js-modal01-slider01-popup').fadeIn(300);
              } else {
                $('.js-modal01-slider01-popup').hide(300);
              }
              if($('.modal01-slider01--02').hasClass('swiper-slide-active')){
                $('.js-modal01-slider02-popup').fadeIn(300);
              } else {
                $('.js-modal01-slider02-popup').hide(300);
              }
            },
          }
        })
      },
    });
    
    
    //02 設定の使い方 モーダル 閉じるボタン
    $('.js-functionModal-close').on('click', function () {
      $('.js-function-modal02').modaal('close');
    })
    //02 設定の使い方 モーダル スライダー
    $(".js-function-modal02").modaal({
      overlay_opacity: '0.3',
      after_open: function(modal_wrapper) {
        //自分のプロフィールの表示について
        new Swiper('.modal02-slider01', {
          loop: true,
          speed: 500,
          lidesPerView: 'auto',
          spaceBetween: 20,
          pagination: {
            el: '.modal02-slider01-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: ".modal02-slider01-next",
            prevEl: ".modal02-slider01-prev",
          },
          on: {
            slideChangeTransitionEnd: function () {
              if($('.modal02-slider01--01').hasClass('swiper-slide-active')){
                $('.js-modal02-slider01-popup01').fadeIn(300);
              } else {
                $('.js-modal02-slider01-popup01').hide(300);
              }
              if($('.modal02-slider01--02').hasClass('swiper-slide-active')){
                $('.js-modal02-slider01-popup02').fadeIn(300);
              } else {
                $('.js-modal02-slider01-popup02').hide(300);
              }
            },
          }
        })
        //その他設定の便利な使い方
        new Swiper('.modal02-slider02', {
          loop: true,
          speed: 500,
          lidesPerView: 'auto',
          spaceBetween: 20,
          pagination: {
            el: '.modal02-slider02-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: ".modal02-slider02-next",
            prevEl: ".modal02-slider02-prev",
          },
          on: {
            slideChangeTransitionEnd: function () {
              if($('.modal02-slider02--01').hasClass('swiper-slide-active')){
                $('.js-modal02-slider02-popup01').fadeIn(300);
              } else {
                $('.js-modal02-slider02-popup01').hide(300);
              }
              if($('.modal02-slider02--02').hasClass('swiper-slide-active')){
                $('.js-modal02-slider02-popup02').fadeIn(300);
              } else {
                $('.js-modal02-slider02-popup02').hide(300);
              }
            },
          }
        })
      },
    });
    
    
    //03 メッセージのやり方 モーダル 閉じるボタン
    $('.js-functionModal-close').on('click', function () {
      $('.js-function-modal03').modaal('close');
    })
    //03 メッセージのやり方 モーダル スライダー
    $(".js-function-modal03").modaal({
      overlay_opacity: '0.3',
    });
    
    const functionTtl = new TimelineMax();
    functionTtl.from('.p-basic__functionTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionTtl)
//    .addIndicators({ name: "functionTtl" })
    .addTo(controller);
    
    const functionSubttl01 = new TimelineMax();
    functionSubttl01.from('.p-basic__functionSubttl--01',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionSubttl--01',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionSubttl01)
//    .addIndicators({ name: "functionSubttl01" })
    .addTo(controller);
    
    const functionPopup = new TimelineMax();
    functionPopup.from('.p-basic__functionPopup',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionPopup',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionPopup)
//    .addIndicators({ name: "functionPopup" })
    .addTo(controller);
    
    const functionPopup02 = new TimelineMax();
    functionPopup02.from('.p-basic__functionSubttl--02',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionSubttl--02',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionPopup02)
//    .addIndicators({ name: "functionPopup02" })
    .addTo(controller);
    
    const functionSpeech = new TimelineMax();
    functionSpeech.from('.p-basic__functionSpeech',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionSpeech',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionSpeech)
//    .addIndicators({ name: "functionSpeech" })
    .addTo(controller);
    
    const functionMovie01 = new TimelineMax();
    functionMovie01.from('.p-basic__functionMovie--01',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionMovie--01',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionMovie01)
//    .addIndicators({ name: "functionMovie01" })
    .addTo(controller);
    
    const functionSpeechTxt = new TimelineMax();
    functionSpeechTxt.from('.p-basic__functionSpeechTxt',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionSpeechTxt',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionSpeechTxt)
//    .addIndicators({ name: "functionSpeechTxt" })
    .addTo(controller);
    
    const functionSubttl03 = new TimelineMax();
    functionSubttl03.from('.p-basic__functionSubttl--03',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionSubttl--03',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionSubttl03)
//    .addIndicators({ name: "functionSubttl03" })
    .addTo(controller);
    
    const functionSlider = new TimelineMax();
    functionSlider.from('.p-basic__functionSlider',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionSlider',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionSlider)
//    .addIndicators({ name: "functionSlider" })
    .addTo(controller);
    
    const functionMoreTtl = new TimelineMax();
    functionMoreTtl.from('.p-basic__functionMoreTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionMoreTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionMoreTtl)
//    .addIndicators({ name: "functionMoreTtl" })
    .addTo(controller);
    
    const functionMoreSlider = new TimelineMax();
    functionMoreSlider.from('.p-basic__functionMoreSlider',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-basic__functionMoreSlider',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionMoreSlider)
//    .addIndicators({ name: "functionMoreSlider" })
    .addTo(controller);
//
//    const rure01 = new TimelineMax();
//    rure01.from('.p-basic__functionModalRuleItem--01',1.0, {
//      opacity: '1',
//      y: '50',
//    })
//    new ScrollMagic.Scene({
//      triggerElement: '.p-basic__functionModalRuleItem--01',
//      triggerHook: 'onCenter',
//      reverse: false,
//    })
//    .setTween(rure01)
//    .addIndicators({ name: "rure01" })
//    .addTo(controller);
//    
//    const rure02 = new TimelineMax();
//    rure02.from('.p-basic__functionModalRuleItem--02',1.0, {
//      opacity: '1',
//      y: '50',
//    })
//    new ScrollMagic.Scene({
//      triggerElement: '.p-basic__functionModalRuleItem--02',
//      triggerHook: 'onCenter',
//      reverse: false,
//    })
//    .setTween(rure02)
//    .addIndicators({ name: "rure02" })
//    .addTo(controller);
//    
//    const rure03 = new TimelineMax();
//    rure03.from('.p-basic__functionModalRuleItem--03',1.0, {
//      opacity: '1',
//      y: '50',
//    })
//    new ScrollMagic.Scene({
//      triggerElement: '.p-basic__functionModalRuleItem--03',
//      triggerHook: 'onCenter',
//      reverse: false,
//    })
//    .setTween(rure03)
//    .addIndicators({ name: "rure03" })
//    .addTo(controller);
//    
//    const rure04 = new TimelineMax();
//    rure04.from('.p-basic__functionModalRuleItem--04',1.0, {
//      opacity: '1',
//      y: '50',
//    })
//    new ScrollMagic.Scene({
//      triggerElement: '.p-basic__functionModalRuleItem--04',
//      triggerHook: 'onCenter',
//      reverse: false,
//    })
//    .setTween(rure04)
//    .addIndicators({ name: "rure04" })
//    .addTo(controller);
    
//    const functionModalCharacter = new TimelineMax();
//    functionModalCharacter.to('.p-basic__functionModalCharacter',1.0, {
//      onComplete: function () {
//        $('.p-basic__functionModalCharacterTxt--01').addClass('fadeIn');
//      }
//    })
//    new ScrollMagic.Scene({
//      triggerElement: '.p-basic__functionModalCharacter',
//      triggerHook: 'onCenter',
//      reverse: false,
//    })
//    .setTween(functionModalCharacter)
////    .setClassToggle('.p-basic__functionModalCharacterTxt', 'fadeIn')
//    .addIndicators({ name: "functionModalCharacter" })
//    .addTo(controller);
    
    
    //04 Exploreの使い方 モーダル 閉じるボタン
    $('.js-functionModal-close').on('click', function () {
      $('.js-function-modal04').modaal('close');
    })
    //03 Exploreの使い方 モーダル スライダー
    $(".js-function-modal04").modaal({
      overlay_opacity: '0.3',
      after_open: function(modal_wrapper) {
        new Swiper('.modal04-slider01', {
          loop: true,
          speed: 500,
          lidesPerView: 'auto',
          spaceBetween: 20,
          pagination: {
            el: '.modal04-slider01-pagination',
            type: 'bullets',
            clickable: true,
          },
          navigation: {
            nextEl: ".modal04-slider01-next",
            prevEl: ".modal04-slider01-prev",
          },
          on: {
            slideChangeTransitionEnd: function () {
              if($('.modal04-slider01--01').hasClass('swiper-slide-active')){
                $('.js-modal04-slider01-popup01').fadeIn(300);
              } else {
                $('.js-modal04-slider01-popup01').hide(300);
              }
              if($('.modal04-slider01--02').hasClass('swiper-slide-active')){
                $('.js-modal04-slider01-popup02').fadeIn(300);
              } else {
                $('.js-modal04-slider01-popup02').hide(300);
              }
            },
          }
        })
      },
    });
    
  }
}
export default basic;