
class Loading {
  constructor() {
    this.init();
  }
  init() {
    const loading = document.querySelector('.p-loading');
    window.addEventListener('load', () => {
      $('body').css('position','fixed');
      setTimeout(function() {
        loading.classList.remove('show');
        loading.classList.add('hide');
        $('body').css('position','relative');
      }, 500);
    });
  }
}

export default Loading;
