/**
 * import Vue
 * ※vueを使用しない場合は削除する
 */
// import App from './vue/App.js';

/**
 * import node_modules
 */
import 'desvg';
import SmoothScroll from 'smooth-scroll';

/**
 * import modules
 */
//import Sample from './modules/sample';
//import Common from './modules/common';
//import Nav from './modules/nav';
//import Slider from './modules/slider';
import Header from './modules/header';
import basic from './modules/basic';
import encounter from './modules/encounter';
import paid from './modules/paid';
import safety from './modules/safety';
import travelGuide from './modules/travelGuide';
import Smoothscroll from './modules/smoothscroll';
import Top from './modules/top';
import TopKv from './modules/topKv';
import Loading from './modules/loading';
// import VueNews from './modules/vue_news';


const target = $('main').attr('id');
switch (target) {
  case 'top':
//    console.log(target)
    new Top();
    new TopKv();
    new Loading();
    break;
  case 'basic':
//    console.log(target)
    new basic();
    break;
  case 'encounter':
//    console.log(target)
    new encounter();
    break;
  case 'paid':
//    console.log(target)
    new paid();
    break;
  case 'safety':
//    console.log(target)
    new safety();
    break;
  case 'travelGuide':
//    console.log(target)
    new travelGuide();
    break;
}


/**
 * Mainクラス
 * @constructor
 */
class Main {
  constructor() {
    this.setup();
  }
  /**
   * インスタンス生成時に実行する処理
   */
  setup() {
    document.addEventListener('DOMContentLoaded', () => {
      this.created();
    });
    window.addEventListener('load', () => {
      this.mounted();
    });
  }
  /**
   * ページ読み込み時に実行する処理
   */
  created() {
//    new Sample('hello'); // クラス内の記述の見本
//    new Common();
//    new Nav();
//    new Slider();
    new Header();
//    new Top();
//    new TopKv();
//    new basic();
    new Smoothscroll();
    // new VueNews();
  }
  /**
   * 画像読み込み後に実行する処理
   */
  mounted() {
    this.init_deSVG('.js-desvg');
    this.init_smoothScroll();
  }

  /**
   * ライブラリ/deSVG
   * インラインsvgをsvgタグに変換
   * https://github.com/benhowdle89/deSVG
   * @param {Element} target ターゲットとなる要素
   */
  init_deSVG(target) {
    deSVG(target, true);
  }

  /**
   * ライブラリ/smooth-scroll
   * スムーススクロール
   * https://github.com/cferdinandi/smooth-scroll
   */
  init_smoothScroll() {
    /**
     * ターゲットとなる要素
     * @type {Element}
     */
    const targetElement = 'a[href*="#"]';

    /**
     * オプション
     * @type {object}
     */
    const option = {
      speed: 1000,
      easing: 'easeInOutCubic',
      speedAsDuration: true,
      //header: '.l-header' 固定ヘッダーの場合
    };

    new SmoothScroll(targetElement, option);
  }
}

export default new Main();
