import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class encounter {
  constructor() {
    this.init();
  }
  init() {
    
    $(function() {
      $("#encounter").on('contextmenu', function() {
        return false;
      });
    });
    
    const controller = new ScrollMagic.Controller();
    const fadeInElements = document.querySelectorAll('.fadeIn');
    fadeInElements.forEach((element) => {
      const fadeInAnim = new TimelineMax();
      fadeInAnim.from(element, 1.0, {
        opacity: '0',
        y: '50',
      });
      new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: '0.6',
        reverse: false,
      })
      .setTween(fadeInAnim)
//      .addIndicators({name: "fadeInAnim"})
      .addTo(controller);
    });
    
  }
}
export default encounter;