import Modaal from "modaal";
import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class paid {
  constructor() {
    this.init();
  }
  init() {
    
    $(function() {
      $("#paid").on('contextmenu', function() {
        return false;
      });
    });
    
    //menuClose
    const menuClose = new TimelineMax();
    menuClose.to('.l-header__menu',0.3, {
      onComplete: function () {
        $('.l-header__menuBtn span').removeClass('active');
        $('.l-header__menuList').slideUp(300);
      }
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-paid__more',
      triggerHook: 'onLeave',
//      offset: '-100',
//      return: true,
    })
    .setTween(menuClose)
//    .addIndicators({ name: "menuClose" })
    .addTo(controller);
    
    //有料プランについて
    $(".js-plan-modal01").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-plan-modal02").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-plan-modal03").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal01").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal02").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal03").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal04").modaal({
      overlay_opacity: '0.3',
    });
    $('.js-modal-close').on('click', function () {
      $('.js-paid-modal').modaal('close');
    })
    
    
    const planTtl = new TimelineMax();
    planTtl.from('.p-paid__planTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-paid__planTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(planTtl)
//    .addIndicators({ name: "planTtl" })
    .addTo(controller);
    
    
    const planContent01 = new TimelineMax();
    planContent01.from('.js-plan-modal01',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.js-plan-modal01',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(planContent01)
//    .addIndicators({ name: "planContent01" })
    .addTo(controller);
    
    
    const planContent02 = new TimelineMax();
    planContent02.from('.js-plan-modal02',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.js-plan-modal02',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(planContent02)
//    .addIndicators({ name: "planContent02" })
    .addTo(controller);
    
    
    const planContent03 = new TimelineMax();
    planContent03.from('.js-plan-modal03',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.js-plan-modal03',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(planContent03)
//    .addIndicators({ name: "planContent03" })
    .addTo(controller);
    
    
    const moreTtl = new TimelineMax();
    moreTtl.from('.p-paid__moreTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-paid__moreTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(moreTtl)
//    .addIndicators({ name: "moreTtl" })
    .addTo(controller);
    
    
    const functionScroll = new TimelineMax();
    functionScroll.from('.m-function__scroll',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.m-function__scroll',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionScroll)
//    .addIndicators({ name: "functionScroll" })
    .addTo(controller);
    
    
    const listTtl = new TimelineMax();
    listTtl.from('.p-paid__listTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-paid__listTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(listTtl)
//    .addIndicators({ name: "listTtl" })
    .addTo(controller);
    
    
    const listscroll = new TimelineMax();
    listscroll.from('.p-paid__listscroll',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-paid__listscroll',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(listscroll)
//    .addIndicators({ name: "listscroll" })
    .addTo(controller);

    
  }
}
export default paid;