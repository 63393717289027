import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class Header {
  constructor() {
    this.init();
  }
  init() {
    //menu
    const menu = new TimelineMax();
    menu.to('.l-header__menu',0.3, {
      opacity : 1,
    })
    new ScrollMagic.Scene({
      triggerElement: '.js-memu-target',
      triggerHook: 'onLeave',
    })
    .setTween(menu)
//    .addIndicators({ name: "menu" })
    .addTo(controller);
    
    
    
    $('.l-header__menuBtn').on('click',function() {
      $(this).next().slideToggle(300);
      $('.l-header__menuBtnInner span').toggleClass('active');
    })
//    $(function(){
//      let pos = 0;
//      $(window).on('scroll', function(){
//        if ($(this).scrollTop() < pos ){
////          $('.l-header__menuBtnInner span').removeClass('active');
//        } else {
//          $('.l-header__menuBtnInner span').addClass('active');
//          $('.l-header__menuList').slideUp(300);
//        }
//        pos = $(this).scrollTop();
//      });
//    });
  }

}

export default Header;
