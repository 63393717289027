import Modaal from "modaal";
import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class safety {
  constructor() {
    this.init();
  }
  init() {
    
    $(function() {
      $("#safety").on('contextmenu', function() {
        return false;
      });
    });
    
    //menuClose
    const menuClose = new TimelineMax();
    menuClose.to('.l-header__menu',0.3, {
      onComplete: function () {
        $('.l-header__menuBtn span').removeClass('active');
        $('.l-header__menuList').slideUp(300);
      }
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-safety__effortBox--02',
      triggerHook: 'onLeave',
//      offset: '-100',
//      return: true,
    })
    .setTween(menuClose)
//    .addIndicators({ name: "menuClose" })
    .addTo(controller);
    
    //安心・安全機能について
    $(".js-slide-modal01").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal02").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal03").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal04").modaal({
      overlay_opacity: '0.3',
      after_open: function(modal_wrapper) {
        $('.modaal-close').addClass('active');
        $('body').addClass('modaal-noscroll');
      }
    });
    $(".js-slide-modal05").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-slide-modal06").modaal({
      overlay_opacity: '0.3',
    });
    
    $('.js-slide-modal03-close').on('click', function () {
      $('.js-safety-modal--03').modaal('close');
    })
    
    $('.js-modal-close').on('click', function () {
      $('.js-safety-modal').modaal('close');
    })
    
    
    //コミュニティガイドラインの詳細
    $(".js-top-slider-modal01").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal02").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal03").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal04").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal05").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal06").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal07").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal08").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal09").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal10").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal11").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal12").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal13").modaal({
      overlay_opacity: '0.3',
    });  
    $(".js-top-slider-modal14").modaal({
      overlay_opacity: '0.3',
    });
    $('.js-modal-close').on('click', function () {
      $('.p-top__communitySlideContent').modaal('close');
    })
    
    const effortTtl = new TimelineMax();
    effortTtl.from('.p-safety__effortTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-safety__effortTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(effortTtl)
//    .addIndicators({ name: "effortTtl" })
    .addTo(controller);
    
    
    const effortSubttl = new TimelineMax();
    effortSubttl.from('.p-safety__effortSubttl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-safety__effortSubttl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(effortSubttl)
//    .addIndicators({ name: "effortSubttl" })
    .addTo(controller);
    
    
    const screen = new TimelineMax();
    screen.from('.p-safety__screen',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-safety__screen',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(screen)
//    .addIndicators({ name: "screen" })
    .addTo(controller);
    
    
    const effortBox = new TimelineMax();
    effortBox.from('.p-safety__effortBox',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-safety__effortBox',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(effortBox)
//    .addIndicators({ name: "effortBox" })
    .addTo(controller);
    
    
    const communityTtl = new TimelineMax();
    communityTtl.from('.p-top__communityTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__communityTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(communityTtl)
//    .addIndicators({ name: "communityTtl" })
    .addTo(controller);
    
    
    const communitySlide = new TimelineMax();
    communitySlide.from('.p-top__communitySlide',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-top__communitySlide',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(communitySlide)
//    .addIndicators({ name: "communitySlide" })
    .addTo(controller);
    
    
    const functionTtl = new TimelineMax();
    functionTtl.from('.p-safety__functionTtl',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.p-safety__functionTtl',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionTtl)
//    .addIndicators({ name: "functionTtl" })
    .addTo(controller);
    
    
    const functionScroll = new TimelineMax();
    functionScroll.from('.m-function__scroll',1.0, {
      opacity: '0',
      y: '50',
    })
    new ScrollMagic.Scene({
      triggerElement: '.m-function__scroll',
      triggerHook: 'onCenter',
      reverse: false,
    })
    .setTween(functionScroll)
//    .addIndicators({ name: "functionScroll" })
    .addTo(controller);
    
    
  }
}
export default safety;