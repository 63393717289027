import Modaal from "modaal";
import {TweenMax} from "gsap";
import {TimelineMax} from "gsap";
import ScrollMagic from "scrollmagic";
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
const controller = new ScrollMagic.Controller;

class travelGuide {
  constructor() {
    this.init();
  }
  init() {
    
    $(function() {
      $("#travelGuide").on('contextmenu', function() {
        return false;
      });
    });
    
    //モーダル閉じるボタンの制御
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-trip').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature01').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature02').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature03').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature04').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature05').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature06').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature07').modaal('close');
    })
    $('.js-modal-close').on('click', function () {
      $('.js-travelGuide-modal-feature08').modaal('close');
    })
    
    //旅ってこんなに楽しいよ
    $(".js-travelGuide-modal-trip").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature01").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature02").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature03").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature04").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature05").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature06").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature07").modaal({
      overlay_opacity: '0.3',
    });
    $(".js-travelGuide-modal-feature08").modaal({
      overlay_opacity: '0.3',
    });
    
    const controller = new ScrollMagic.Controller();
    const fadeInElements = document.querySelectorAll('.fadeIn');
    fadeInElements.forEach((element) => {
      const fadeInAnim = new TimelineMax();
      fadeInAnim.from(element, 1.0, {
        opacity: '0',
        y: '50',
      });
      new ScrollMagic.Scene({
        triggerElement: element,
        triggerHook: '0.7',
        reverse: false,
      })
      .setTween(fadeInAnim)
//      .addIndicators({name: "fadeInAnim"})
      .addTo(controller);
    });
    
    
  }
}
export default travelGuide;