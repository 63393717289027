/**
 * TopKvクラス
 * @constructor
 */
class TopKv {
  constructor() {
    // インスタンス変数(メンバ変数)には頭に「 _ 」をつけましょう
    // this._aaa = 'インスタンス変数です。どの関数からも参照出来ます';

    this._world = [];
		this._engine = [];
		this._render = [];
		this._canvasData = [];
		this._matterSetted = [];
		this._matterAppeared = [];
    this._matterVisualContainer = document.querySelectorAll(".js-matterVisual");
		this._isDesktop = window.matchMedia("(min-width: 769px)").matches;

    /* svg 書き出し用ダミー要素 */
    document.body.insertAdjacentHTML("beforeend", "<div id=\"d\" style=\"display:none\"></div>");
    this.d = document.getElementById("d");

    this._sticker = {
      "01": {
        "configurations": "svg",
        "group": "",
        "width": 213.5,
        "height": 80.5,
        "scale": 0.9,
        "path": "m8.77,42.47c3.97-6.45-11.31-18.59-1.47-28.63,14.05-11.52,17.15-22.5,32.45-3.66,24.93-16.15,112.9-6.12,140.95-4.99,5.09-5.29,25.06-5.67,22.51,5.37-5.76,10.78,4.88,8.15,5.4,16.77-4.07,8.06,2.92,6.84,4.41,13.08,3.07,14.68-9.88,6.56-11.52,15.81-1.83,8.82-2.59,22-14.27,22.84-25.58-6.15-100.26,12.29-125.56-9.66-21,18.6-84.81,12.19-52.91-26.92Z",
        "x": 330,
        "y": 1010 - 1160,
        "degree": 10
      },
      "02": {
        "configurations": "svg",
        "group": "",
        "width": 167.5,
        "height": 163,
        "scale": 0.9,
        "path": "m153.8,94.57c-19.94,26.72-50.56,41.75-70.55,68.43C50.17,124.61-24.11,86.22,7.79,26.58,21.97.43,61.23-7.84,82.44,14.5c52.53-47.09,112.58,31.45,71.36,80.07Z",
        "x": 170,
        "y": 860 - 1160,
        "degree": 0
      },
      "03": {
        "configurations": "rectangle",
        "group": "",
        "width": 132,
        "height": 113.5,
        "scale": 1,
        "x": 70,
        "y": 980 - 1160,
        "degree": 10
      },
      "04": {
        "configurations": "rectangle",
        "group": "",
        "width": 146,
        "height": 102.5,
        "scale": 1,
        "x": 320,
        "y": 850 - 1160,
        "degree": -30
      },
      "05": {
        "configurations": "circle",
        "group": "",
        "width": 149,
        "height": 149,
        "scale": 1,
        "x": 310,
        "y": 720 - 1160,
        "degree": 0
      },
      "06": {
        "configurations": "svg",
        "group": "",
        "width": 92.5,
        "height": 96,
        "scale": 0.9,
        "path": "m2.47,55.89C-7.93,33.95,16.26-13.63,42.68,3.74c15.51-2.61,27.26,8.32,30.94,23.32,2.34,7.91,2.9,15.94.44,24.44,31.36-2.25,18.15,34.76-5.66,36.75-11.59,12.83-32.78,6.08-48.03,5.06-22.35-5.79-4.11-26.12-17.9-37.42Z",
        "x": 190,
        "y": 740 - 1160,
        "degree": 45
      },
      "07": {
        "configurations": "svg",
        "group": "",
        "width": 128.5,
        "height": 158.5,
        "scale": 0.9,
        "path": "m27.04,158.5H.05c0-.97,0-1.85,0-2.73,0-31.06.09-62.12-.05-93.18-.05-9.9,2.47-19.09,6.8-27.77,5.4-10.82,13.45-19.43,23.85-25.65C40.43,3.32,50.95.23,62.52.01c12.67-.24,24.34,2.61,35.04,9.18,15.26,9.38,24.99,22.99,29.21,40.34,1.18,4.84,1.62,9.96,1.65,14.96.15,30.31.07,60.62.07,90.93,0,.91,0,1.82,0,3.06H27.04Z",
        "x": 40,
        "y": 720 - 1160,
        "degree": -20
      },
      "08": {
        "configurations": "rectangle",
        "group": "",
        "width": 130,
        "height": 88,
        "scale": 1,
        "x": 340,
        "y": 500 - 1160,
        "degree": 20
      },
      // "09": {
      //   "configurations": "svg",
      //   "group": "",
      //   "width": 292,
      //   "height": 103,
      //   "scale": 0.9,
      //   "path": "m1.81,50.49C-11.52,3.38,52.22-14.52,80.94,13c16.2-19.83,59.83-17.33,50.04,15.91-1.93,6.43-5.41,12.26,3.74,9.28,30.73,4.02,23.58-41.25,61.28-.62,1.54-27.2,36.61-54.44,52.49-22.38,20.43-31.18,57.58,3.59,37.96,32.13-12.43,44.85-2.6,56.45-58.35,49.91-37.13-5.86-132.13,17.82-152.63-7.52-13.94-5.23-19.11,10.84-32.04,12.52C2.14,108.44,9.6,75.83,1.81,50.49Z",
      //   "x": 220,
      //   "y": 640 - 1160,
      //   "degree": -30
      // },
      "10": {
        "configurations": "polygon",
        "group": "",
        "width": 117,
        "height": 117,
        "scale": 1,
        "x": 55,
        "y": 550 - 1160,
        "num": 8,
        "degree": -10
      },
      // "11": {
      //   "configurations": "svg",
      //   "group": "",
      //   "width": 146.5,
      //   "height": 147.5,
      //   "scale": 0.9,
      //   "path": "m142.43,89.29c-1.19,3.53-8.52,6.41-5.87,9.84,19.66,17.4-14.01,55.73-33.71,40.28-18.36-7.53-38.38,21.74-52.38-.27-17.56-6.58-21.92-24.52-10.53-38.55-20.7,14.54-29.16-15.44-38.28-28.75-9.46-17.54,24.13-36.4,37.65-27.3,13.16-97.21,130.28-13.46,103.12,44.75Z",
      //   "x": 200,
      //   "y": -30 - 1160,
      //   "degree": 0
      // },
      "12": {
        "configurations": "svg",
        "group": "",
        "width": 127,
        "height": 115,
        "scale": 0.9,
        "path": "m43.54,8.28c20.36-3.23,53.05,16.7,61.19,38.51,76.91,64.79-68.55,100.66-87.54,27.98C14.93,60.65-3.62,57.66.63,42.76,7.04,19.5,17.25-16.32,43.54,8.28Z",
        "x": 70,
        "y": 200 - 1160,
        "degree": 0
      },
      "13": {
        "configurations": "rectangle",
        "group": "",
        "width": 164.5,
        "height": 146.5,
        "scale": 1,
        "x": 325,
        "y": -130 - 1160,
        "degree": 50
      },
      "14": {
        "configurations": "svg",
        "group": "",
        "width": 140,
        "height": 111.5,
        "scale": 0.9,
        "path": "m68.47,111.48c-20.64,0-41.04.01-61.43.02-4.56,0-7.02-2.45-7.03-7.01,0-12.56-.05-25.12.02-37.69.05-10.14,2.97-19.53,7.43-28.58,3.3-6.69,7.63-12.63,12.84-17.84,6.7-6.7,14.57-11.78,23.37-15.48C54.93.16,66.74-.7,78.56.46c13.02,1.28,24.77,6.49,35.01,14.75,11.88,9.59,20.08,21.71,23.87,36.52,1.36,5.31,2.21,10.87,2.37,16.34.35,12.05.12,24.12.11,36.19,0,4.61-2.6,7.21-7.26,7.21-21.31.01-42.62,0-64.18,0Z",
        "x": 40,
        "y": -30 - 1160,
        "degree": -10
      },
      // "15": {
      //   "configurations": "svg",
      //   "group": "",
      //   "width": 222.5,
      //   "height": 105,
      //   "scale": 0.9,
      //   "path": "m75.41,3.75c43.46,3.76,86.77,7,130.24,10.26,9.66.46,17.07,9.12,16.84,17.54-4,44.77,5.11,62.36-49.61,54.1-29.44-2.16-58.92-4.16-88.33-6.57-3.24.09-5.85,1.51-7.8,4.14-10.79,15.01-28.08,21.55-46.1,21.77-5.3.44-8.18-7.11-4.2-10.52,5.25-5.11,11.3-9.5,14.33-16.41.85-1.53.42-2.48-1.51-2.63C-14.15,74.13,2.07,57.31,3.11,15.56,3.68,7.98,11.79-.52,20.07.02c18.55,1.2,36.7,2.33,55.34,3.72Z",
      //   "x": 140,
      //   "y": -140 - 1160,
      //   "degree": -30
      // },
      "16": {
        "configurations": "svg",
        "group": "",
        "width": 45,
        "height": 40.5,
        "scale": 1.1,
        "path": "m10.63,40.32c-4.25-2.29-5.32-5.25-3.73-11.07.46-1.7.13-2.58-1.19-3.49-1.01-.7-1.88-1.61-2.84-2.38C-.32,20.82-.37,17.23.44,13.89c.72-2.92,3.86-3.52,6.51-4.11,1.6-.36,3.29-.29,4.91-.58.57-.1,1.2-.62,1.52-1.12,1.08-1.71,1.92-3.59,3.07-5.25,2.49-3.57,9.25-3.91,11.24-.16,2.44,4.59,5.15,7.35,10.87,6.81,2.51-.24,5.26,1.42,6.07,4.29.82,2.92.29,5.75-2.12,7.94-1.53,1.39-3.11,2.72-4.71,4.11.43,2.51,1.14,5.06,1.26,7.64.23,4.85-3.61,7.9-8.35,6.64-1.96-.52-3.88-1.47-5.56-2.6-1.52-1.01-2.62-1.3-4.12-.06-2.94,2.43-6.35,3.51-10.41,2.88Z",
        "x": 20,
        "y": 870 - 1160,
        "degree": 0
      },
      "17": {
        "configurations": "svg",
        "group": "",
        "width": 45,
        "height": 40.5,
        "scale": 1.1,
        "path": "m10.63,40.32c-4.25-2.29-5.32-5.25-3.73-11.07.46-1.7.13-2.58-1.19-3.49-1.01-.7-1.88-1.61-2.84-2.38C-.32,20.82-.37,17.23.44,13.89c.72-2.92,3.86-3.52,6.51-4.11,1.6-.36,3.29-.29,4.91-.58.57-.1,1.2-.62,1.52-1.12,1.08-1.71,1.92-3.59,3.07-5.25,2.49-3.57,9.25-3.91,11.24-.16,2.44,4.59,5.15,7.35,10.87,6.81,2.51-.24,5.26,1.42,6.07,4.29.82,2.92.29,5.75-2.12,7.94-1.53,1.39-3.11,2.72-4.71,4.11.43,2.51,1.14,5.06,1.26,7.64.23,4.85-3.61,7.9-8.35,6.64-1.96-.52-3.88-1.47-5.56-2.6-1.52-1.01-2.62-1.3-4.12-.06-2.94,2.43-6.35,3.51-10.41,2.88Z",
        "x": 200,
        "y": 1130 - 1160,
        "degree": 0
      },
      "18": {
        "configurations": "svg",
        "group": "",
        "width": 45,
        "height": 40.5,
        "scale": 1.1,
        "path": "m10.63,40.32c-4.25-2.29-5.32-5.25-3.73-11.07.46-1.7.13-2.58-1.19-3.49-1.01-.7-1.88-1.61-2.84-2.38C-.32,20.82-.37,17.23.44,13.89c.72-2.92,3.86-3.52,6.51-4.11,1.6-.36,3.29-.29,4.91-.58.57-.1,1.2-.62,1.52-1.12,1.08-1.71,1.92-3.59,3.07-5.25,2.49-3.57,9.25-3.91,11.24-.16,2.44,4.59,5.15,7.35,10.87,6.81,2.51-.24,5.26,1.42,6.07,4.29.82,2.92.29,5.75-2.12,7.94-1.53,1.39-3.11,2.72-4.71,4.11.43,2.51,1.14,5.06,1.26,7.64.23,4.85-3.61,7.9-8.35,6.64-1.96-.52-3.88-1.47-5.56-2.6-1.52-1.01-2.62-1.3-4.12-.06-2.94,2.43-6.35,3.51-10.41,2.88Z",
        "x": 380,
        "y": 950 - 1160,
        "degree": 0
      },
      "19": {
        "configurations": "svg",
        "group": "",
        "width": 34,
        "height": 46,
        "scale": 1.2,
        "path": "m17.38.98c12.84-4.57,10.33,7.99,8.7,15.39,28.41,6.55-29.74,52.37-17.31,15.34C-12.76,27.13,11.36,9.85,17.38.98Z",
        "x": 130,
        "y": 1080 - 1160,
        "degree": 0
      },
      "20": {
        "configurations": "svg",
        "group": "",
        "width": 34,
        "height": 46,
        "scale": 1.2,
        "path": "m17.38.98c12.84-4.57,10.33,7.99,8.7,15.39,28.41,6.55-29.74,52.37-17.31,15.34C-12.76,27.13,11.36,9.85,17.38.98Z",
        "x": 280,
        "y": 440 - 1160,
        "degree": 20
      },
      "21": {
        "configurations": "circle",
        "group": "",
        "width": 43,
        "height": 44.5,
        "scale": 1.2,
        "x": 320,
        "y": 940 - 1160,
        "degree": 0
      },
      "22": {
        "configurations": "circle",
        "group": "",
        "width": 43,
        "height": 44.5,
        "scale": 1.2,
        "x": 30,
        "y": 475 - 1160,
        "degree": 0
      },
      "23": {
        "configurations": "circle",
        "group": "",
        "width": 43,
        "height": 44.5,
        "scale": 1.2,
        "x": 365,
        "y": 420 - 1160,
        "degree": 0
      },
      "24": {
        "configurations": "rectangle",
        "group": "",
        "width": 132,
        "height": 113.5,
        "scale": 1,
        "x": 160,
        "y": 440 - 1160,
        "degree": 0
      },
      "25": {
        "configurations": "svg",
        "group": "",
        "width": 213.5,
        "height": 80.5,
        "scale": 0.9,
        "path": "m8.77,42.47c3.97-6.45-11.31-18.59-1.47-28.63,14.05-11.52,17.15-22.5,32.45-3.66,24.93-16.15,112.9-6.12,140.95-4.99,5.09-5.29,25.06-5.67,22.51,5.37-5.76,10.78,4.88,8.15,5.4,16.77-4.07,8.06,2.92,6.84,4.41,13.08,3.07,14.68-9.88,6.56-11.52,15.81-1.83,8.82-2.59,22-14.27,22.84-25.58-6.15-100.26,12.29-125.56-9.66-21,18.6-84.81,12.19-52.91-26.92Z",
        "x": 35,
        "y": 340 - 1160,
        "degree": 65
      },
      "26": {
        "configurations": "svg",
        "group": "",
        "width": 128.5,
        "height": 158.5,
        "scale": 0.9,
        "path": "m27.04,158.5H.05c0-.97,0-1.85,0-2.73,0-31.06.09-62.12-.05-93.18-.05-9.9,2.47-19.09,6.8-27.77,5.4-10.82,13.45-19.43,23.85-25.65C40.43,3.32,50.95.23,62.52.01c12.67-.24,24.34,2.61,35.04,9.18,15.26,9.38,24.99,22.99,29.21,40.34,1.18,4.84,1.62,9.96,1.65,14.96.15,30.31.07,60.62.07,90.93,0,.91,0,1.82,0,3.06H27.04Z",
        "x": 360,
        "y": 50 - 1160,
        "degree": 50
      },
      "27": {
        "configurations": "svg",
        "group": "",
        "width": 140,
        "height": 111.5,
        "scale": 0.9,
        "path": "m68.47,111.48c-20.64,0-41.04.01-61.43.02-4.56,0-7.02-2.45-7.03-7.01,0-12.56-.05-25.12.02-37.69.05-10.14,2.97-19.53,7.43-28.58,3.3-6.69,7.63-12.63,12.84-17.84,6.7-6.7,14.57-11.78,23.37-15.48C54.93.16,66.74-.7,78.56.46c13.02,1.28,24.77,6.49,35.01,14.75,11.88,9.59,20.08,21.71,23.87,36.52,1.36,5.31,2.21,10.87,2.37,16.34.35,12.05.12,24.12.11,36.19,0,4.61-2.6,7.21-7.26,7.21-21.31.01-42.62,0-64.18,0Z",
        "x": 190,
        "y": 550 - 1160,
        "degree": 10
      },
      "28": {
        "configurations": "svg",
        "group": "",
        "width": 127,
        "height": 115,
        "scale": 0.9,
        "path": "m43.54,8.28c20.36-3.23,53.05,16.7,61.19,38.51,76.91,64.79-68.55,100.66-87.54,27.98C14.93,60.65-3.62,57.66.63,42.76,7.04,19.5,17.25-16.32,43.54,8.28Z",
        "x": 40,
        "y": 1080 - 1160,
        "degree": 20
      },
      "29": {
        "configurations": "circle",
        "group": "",
        "width": 149,
        "height": 149,
        "scale": 1,
        "x": 270,
        "y": 360 - 1160,
        "degree": 0
      },
      "30": {
        "configurations": "polygon",
        "group": "",
        "width": 117,
        "height": 117,
        "scale": 1,
        "x": 330,
        "y": 250 - 1160,
        "num": 8,
        "degree": -10
      },
      "31": {
        "configurations": "rectangle",
        "group": "",
        "width": 146,
        "height": 102.5,
        "scale": 1,
        "x": 130,
        "y": 300 - 1160,
        "degree": -25
      },
      "32": {
        "configurations": "svg",
        "group": "",
        "width": 45,
        "height": 40.5,
        "scale": 1.1,
        "path": "m10.63,40.32c-4.25-2.29-5.32-5.25-3.73-11.07.46-1.7.13-2.58-1.19-3.49-1.01-.7-1.88-1.61-2.84-2.38C-.32,20.82-.37,17.23.44,13.89c.72-2.92,3.86-3.52,6.51-4.11,1.6-.36,3.29-.29,4.91-.58.57-.1,1.2-.62,1.52-1.12,1.08-1.71,1.92-3.59,3.07-5.25,2.49-3.57,9.25-3.91,11.24-.16,2.44,4.59,5.15,7.35,10.87,6.81,2.51-.24,5.26,1.42,6.07,4.29.82,2.92.29,5.75-2.12,7.94-1.53,1.39-3.11,2.72-4.71,4.11.43,2.51,1.14,5.06,1.26,7.64.23,4.85-3.61,7.9-8.35,6.64-1.96-.52-3.88-1.47-5.56-2.6-1.52-1.01-2.62-1.3-4.12-.06-2.94,2.43-6.35,3.51-10.41,2.88Z",
        "x": 180,
        "y": 350 - 1160,
        "degree": 0
      },
      "33": {
        "configurations": "circle",
        "group": "",
        "width": 43,
        "height": 44.5,
        "scale": 1.2,
        "x": 235,
        "y": 480 - 1160,
        "degree": 0
      },
      "34": {
        "configurations": "svg",
        "group": "",
        "width": 167.5,
        "height": 163,
        "scale": 0.9,
        "path": "m153.8,94.57c-19.94,26.72-50.56,41.75-70.55,68.43C50.17,124.61-24.11,86.22,7.79,26.58,21.97.43,61.23-7.84,82.44,14.5c52.53-47.09,112.58,31.45,71.36,80.07Z",
        "x": 220,
        "y": 150 - 1160,
        "degree": 120
      },
      "35": {
        "configurations": "rectangle",
        "group": "",
        "width": 130,
        "height": 88,
        "scale": 1,
        "x": 80,
        "y": 90 - 1160,
        "degree": 70
      },
      "36": {
        "configurations": "circle",
        "group": "",
        "width": 43,
        "height": 44.5,
        "scale": 1.2,
        "x": 380,
        "y": -30 - 1160,
        "degree": 0
      },
      "37": {
        "configurations": "svg",
        "group": "",
        "width": 34,
        "height": 46,
        "scale": 1.2,
        "path": "m17.38.98c12.84-4.57,10.33,7.99,8.7,15.39,28.41,6.55-29.74,52.37-17.31,15.34C-12.76,27.13,11.36,9.85,17.38.98Z",
        "x": 75,
        "y": 455 - 1160,
        "degree": 0
      },
      "38": {
        "configurations": "svg",
        "group": "",
        "width": 222.5,
        "height": 105,
        "scale": 0.9,
        "path": "m75.41,3.75c43.46,3.76,86.77,7,130.24,10.26,9.66.46,17.07,9.12,16.84,17.54-4,44.77,5.11,62.36-49.61,54.1-29.44-2.16-58.92-4.16-88.33-6.57-3.24.09-5.85,1.51-7.8,4.14-10.79,15.01-28.08,21.55-46.1,21.77-5.3.44-8.18-7.11-4.2-10.52,5.25-5.11,11.3-9.5,14.33-16.41.85-1.53.42-2.48-1.51-2.63C-14.15,74.13,2.07,57.31,3.11,15.56,3.68,7.98,11.79-.52,20.07.02c18.55,1.2,36.7,2.33,55.34,3.72Z",
        "x": 140,
        "y": -140 - 1160,
        "degree": -60
      },
      "39": {
        "configurations": "svg",
        "group": "",
        "width": 292,
        "height": 103,
        "scale": 0.9,
        "path": "m1.81,50.49C-11.52,3.38,52.22-14.52,80.94,13c16.2-19.83,59.83-17.33,50.04,15.91-1.93,6.43-5.41,12.26,3.74,9.28,30.73,4.02,23.58-41.25,61.28-.62,1.54-27.2,36.61-54.44,52.49-22.38,20.43-31.18,57.58,3.59,37.96,32.13-12.43,44.85-2.6,56.45-58.35,49.91-37.13-5.86-132.13,17.82-152.63-7.52-13.94-5.23-19.11,10.84-32.04,12.52C2.14,108.44,9.6,75.83,1.81,50.49Z",
        "x": 220,
        "y": 640 - 1160,
        "degree": -30
      },
      "40": {
        "configurations": "svg",
        "group": "",
        "width": 146.5,
        "height": 147.5,
        "scale": 0.9,
        "path": "m142.43,89.29c-1.19,3.53-8.52,6.41-5.87,9.84,19.66,17.4-14.01,55.73-33.71,40.28-18.36-7.53-38.38,21.74-52.38-.27-17.56-6.58-21.92-24.52-10.53-38.55-20.7,14.54-29.16-15.44-38.28-28.75-9.46-17.54,24.13-36.4,37.65-27.3,13.16-97.21,130.28-13.46,103.12,44.75Z",
        "x": 200,
        "y": -30 - 1160,
        "degree": 0
      }
    }

    this.init();

    var resizeTimer;
    window.addEventListener("resize", () => {
      if (resizeTimer !== false) clearTimeout(resizeTimer);
      resizeTimer = setTimeout(() => {
        if (document.querySelector(".js-matterVisual") !== null) {
          this.canvasRefresh();
        }
      }, 200);
    }, false);
  }
  /**
   * TopKv
   */
  init() {
    // this.consoleFunc(this._aaa);

    for (let i = 0, len = this._matterVisualContainer.length; i < len; i += 1) {
      const c = this._matterVisualContainer[i];

			this.setting(c, i);
			this.createCanvas(c, i);
			this.createContainer(i);

      this._matterAppeared[i] = true;
      this.createSticker(i);
		}

    const logo = document.getElementsByClassName("p-topKv__logo");
    setTimeout(function() {
      logo[0].classList.add("is-fadeIn");
    }, 500);
  }

  // consoleFunc(val) {
  //   console.log(val);
  // }

  setting(c, n) {
    const minWidth = this._isDesktop ? 393 : 750;
		// const heightRatio = minWidth / document.body.clientWidth;

		this._canvasData[n] = {
			// parentClass: c.parentNode.getAttribute("class"),
			// scope: c.getAttribute("data-in"),
			minWidth: this._isDesktop ? 393 : 750,
			canvasWidth: c.clientWidth,
			canvasHeight: c.clientHeight,
			// canvasWidth: c.clientWidth > minWidth ? c.clientWidth : minWidth,
			// canvasHeight: c.clientHeight * heightRatio < c.clientHeight ? c.clientHeight : c.clientHeight * heightRatio,
			wallOption: {
				isStatic: true,
				render: {
					fillStyle: "none",
					strokeStyle: "none",
					lineWidth: 0,
				},
			},
			stickerOption: {
        // isStatic: true,
				density: 0.4, // 密度: 単位面積あたりの質量
				frictionAir: 0.05, // 空気抵抗(空気摩擦)
				restitution: 0.05, // 弾力性
				friction: 0.5, // 本体の摩擦
				timeScale: 1,
			},
		}
  }

  createCanvas(c, i) {
    const n = i;
		const d = this._canvasData[n];

		this._engine[n] = Matter.Engine.create();
		this._world[n] = this._engine[n].world;

		/* レンダリング設定 */
		this._render[n] = Matter.Render.create({
			element: c,
			engine: this._engine[n],
			options: {
				width: d.canvasWidth,
				height: d.canvasHeight,
				wireframes: false,
				showAngleIndicator: false,
				background: "rgba(255,255,255,0)",
			}
		});
		Matter.Render.run(this._render[n]);
		Matter.Runner.run(this._engine[n]);

		/* マウス操作 */
		// const mouse = Matter.Mouse.create(this._render[n].canvas);
		// const mouseConstraint = Matter.MouseConstraint.create(this._engine[n], {
		// 	mouse: mouse,
		// 	constraint: {
		// 		stiffness: 1,
		// 		angularStiffness: 0,
		// 		render: {
		// 			visible: false
		// 		}
		// 	}
		// });
		// this._render[n].mouse = mouse;
		// Matter.World.add(this._world[n], mouseConstraint);

		// this._matterSetted[n] = true;
  }

  createContainer(n) {
    const d = this._canvasData[n];

    // 床・壁
		Matter.World.add(this._world[n], [
			Matter.Bodies.rectangle(d.canvasWidth / 2, d.canvasHeight + 45, d.canvasWidth + 100, 100, d.wallOption),
			Matter.Bodies.rectangle(-100, 24, 100, d.canvasHeight * 4, d.wallOption),
			Matter.Bodies.rectangle(d.canvasWidth + 101, 24, 100, d.canvasHeight * 4, d.wallOption),
		]);
  }

  createSticker(n) {
    const d = this._canvasData[n];

		const bodiesOption = d.stickerOption;
		let x = 0, y = 0, rows = 5,
			obj, objScale, angle, rest, objWidth, objHeight, objImg,
			vertexSets = [];

    const stickerData = this._sticker;
    const stickerArray = Object.keys(stickerData);
    const sortedsStickerArray = stickerArray.sort((a, b) => parseInt(a) - parseInt(b));

    var agent = window.navigator.userAgent.toLowerCase();
    var ipad = agent.indexOf('ipad') > -1 || agent.indexOf('macintosh') > -1 && 'ontouchend' in document;

    for (let i = 0, len = sortedsStickerArray.length; i < len; i += 1) {
      // if (!this._isDesktop) {
      //   if (sortedsStickerArray[i] == n) {
      //     continue;
      //   }
      // }

			obj = stickerData[sortedsStickerArray[i]];
			objScale = obj.scale;

      if (this._isDesktop) {
        objWidth = obj.width * objScale;
        objHeight = obj.height * objScale;
      } else if (ipad) {
        objWidth = (obj.width * objScale) * (d.canvasWidth * 0.75 / 393);
        objHeight = (obj.height * objScale) * (d.canvasWidth * 0.75 / 393);
      } else {
        objWidth = (obj.width * objScale) * (d.canvasWidth * 1 / 393);
        objHeight = (obj.height * objScale) * (d.canvasWidth * 1 / 393);
      }

      // ステッカーを並べる
      // x = x + objWidth > d.canvasWidth ? objWidth * 2 : x + objWidth;
      // if (x + objWidth > d.canvasWidth) {
      //   rows =- 1;
      // }
      // y = -d.canvasHeight / 2;
      if (this._isDesktop) {
        x = obj.x;
        y = obj.y;
      } else {
        x = obj.x * (d.canvasWidth / 393);
        y = obj.y;
      }

      objImg = "./assets/img/sticker_" + stickerArray[i] + ".webp";
      if (this._isDesktop) {
        bodiesOption.render = {
          sprite: {
            texture: objImg,
            xScale: 1 / 2,
            yScale: 1 / 2,
          },
        };
      } else if (ipad) {
        bodiesOption.render = {
          sprite: {
            texture: objImg,
            xScale: (1 / 2) * (d.canvasWidth * 0.75 / 393),
            yScale: (1 / 2) * (d.canvasWidth * 0.75 / 393),
          },
        };
      } else {
        bodiesOption.render = {
          sprite: {
            texture: objImg,
            xScale: (1 / 2) * (d.canvasWidth * 1 / 393),
            yScale: (1 / 2) * (d.canvasWidth * 1 / 393),
          },
        };
      }
      bodiesOption.angle = obj.degree * ( Math.PI / 180 );

      if (!this._isDesktop && !ipad) {
        objScale = obj.scale * (d.canvasWidth * 1 / 393);
      } else if (ipad) {
        objScale = obj.scale * (d.canvasWidth * 0.7 / 393);
      }
      switch(obj.configurations) {
        case "rectangle":
        	Matter.World.add(this._world[n],
        		Matter.Bodies.rectangle(x , y, objWidth - 10, objHeight - 10, bodiesOption)
        	);
        break;
        case "polygon":
        	Matter.World.add(this._world[n],
        		Matter.Bodies.polygon(x , y, obj.num, objHeight / 2, bodiesOption)
        	);
        break;
        case "circle":
        	Matter.World.add(this._world[n],
        		Matter.Bodies.circle(x, y, objWidth / 2 - 10, bodiesOption)
        	);
        break;
        case "svg":
          vertexSets = [];
          this.d.innerHTML = "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"".concat(objWidth, "\" height=\"").concat(objHeight, "\" viewBox=\"0 0 ").concat(objWidth, " ").concat(objHeight, "\"><path d=\"").concat(obj.path, "\"/></svg>");
          const path = this.d.querySelector("svg path");
          const points = Matter.Svg.pathToVertices(path, 30);
          vertexSets.push(Matter.Vertices.scale(points, objScale, objScale));
          Matter.World.add(this._world[n],
            Matter.Bodies.fromVertices(x, y, vertexSets, bodiesOption, false)
          );
        break;
      }
		}
		/* Matter.Render.lookAt(render, objects, [padding], [center=true]) */
		Matter.Render.lookAt(this._render[n], {
			min: { x: 0, y: 0 },
			max: { x: d.canvasWidth, y: d.canvasHeight }
		});
  }

  canvasRefresh() {
		if (this._isDesktop !== window.matchMedia("(min-width: 769px)").matches) {
			// isDesktop が変わったら、キャンバスごと作り直す
			this._isDesktop = window.matchMedia("(min-width: 769px)").matches;
			for (let i = 0, len = this._matterVisualContainer.length; i < len; i += 1) {
				// ISSUE: ここでちゃんと World と bodies を消す
				this._canvasData[i].minWidth = this._isDesktop ? 393 : 750;
				this.canvasResize(this._matterVisualContainer[i], i);
				this.refreshAction(this._matterVisualContainer[i], i);
			}
		} else {
			// MEMO: 変わってなければキャンバスと床・壁のサイズだけ変更
			// for (let i = 0, len = this._matterVisualContainer.length; i < len; i += 1) {
			// 	this.canvasResize(this._matterVisualContainer[i], i);

			// 	// ISSUE: 床と壁の位置変更する
      //   this._render[i].options.width = this._matterVisualContainer[i].clientWidth;
			// }
		}
	}

	canvasResize(c, n) {
		const d = this._canvasData[n];
		d.canvasWidth = c.clientWidth > d.minWidth ? c.clientWidth : d.minWidth,
		d.canvasHeight = c.clientHeight,
		// const heightRatio = d.minWidth / document.body.clientWidth;
		// d.canvasHeight = c.clientHeight * heightRatio < c.clientHeight ? c.clientHeight : c.clientHeight * heightRatio,

    this._render[n].canvas.height = c.clientHeight;
		// this._render[n].canvas.height = c.clientHeight * heightRatio;
	}

	refreshAction(c, n) {
    const logo = document.getElementsByClassName("c-kv__logo");
    logo[0].classList.remove("is-fadeIn");

		const canvasElm = c.querySelector("canvas");
    c.removeChild(canvasElm);
		this._matterAppeared[n] = false;
		this.createCanvas(c, n);
		this.createContainer(n);
    this._matterAppeared[n] = true;
    this.createSticker(n);

    logo[0].classList.add("is-fadeIn");
	}
}

export default TopKv;
